import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface DataTypes<T> {
  [key: string]: { data: T; loading: boolean; error: string; loadingNewData: boolean };
}

interface DataState<T> {
  dataTables: DataTypes<T>;
}

const initialState: Readonly<DataState<any>> = {
  dataTables: {},
};

// const keyValue = ()

const tableData = createSlice({
  name: 'dataTables',
  initialState,
  reducers: {
    createDataStorageName: (state, action: PayloadAction<string>) => {
      state.dataTables = {
        ...state.dataTables,
        [action.payload]: { data: [], loading: true, loadingNewData: false, error: '' },
      };
    },
    loadNewData: (state, action: PayloadAction<{ nameStorage: string; loading: boolean }>) => {
      state.dataTables[action.payload.nameStorage].loadingNewData = action.payload.loading;
    },
    setData: (state, action: PayloadAction<any>) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      state.dataTables[key].loading = false;
      state.dataTables[key].loadingNewData = false;
      state.dataTables[key].data = value;
    },

    setDataLoading: (state, action: PayloadAction<{ nameStorage: string; loading: boolean }>) => {
      state.dataTables[action.payload.nameStorage].loading = action.payload.loading;
      state.dataTables[action.payload.nameStorage].loadingNewData = action.payload.loading;
    },
  },
});

export const { setData, setDataLoading, createDataStorageName, loadNewData } = tableData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default tableData.reducer;
