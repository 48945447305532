import { type ProductsGategoriesMaxDiscountsOptionsData } from '@/types/optionsTypes';
import { type TaxesOptionsData } from '@/types/optionsTypes';
import { type ChartBarStackType, type ChartDaum, type GetProductData, type Product, type User } from '@/types';
import dayjs from 'dayjs';
import { type MutableRefObject } from 'react';
import { type LoaderType, type ItemLoaderColumns } from '@sobrus-com/sobrus-design-system-v2';
export function generateRandomId(prefix = ''): string {
  const timestamp = Date.now().toString(36); // Encodes current timestamp
  const randomSection = Math.random().toString(36).substring(2, 9); // Generates a random alphanumeric string
  const uniqueSection = Math.random().toString(36).substring(2, 4) + ((Math.random() * 100000000) | 0).toString(36); // Adds another level of randomness
  return `${prefix}${timestamp}-${randomSection}-${uniqueSection}`;
}
export function isWindows7(): boolean {
  const userAgent = window.navigator.userAgent;
  return /Windows NT 6.1/.test(userAgent);
}

export function objectToString(obj: Record<string, any>): string {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        // If the value is an object, recursively call objectToString
        return `${key}:${objectToString(value)}`;
      } else {
        // If the value is not an object, concatenate key and value
        return `${key}:${value}`;
      }
    })
    .join(',');
}
export function fileToFileList(file: File): FileList {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  return dataTransfer.files;
}

export const urltoFile = async (filename: string, baseUrl: string, cd: (x: File) => void) => {
  const mimeType = filename?.slice(filename?.lastIndexOf('.'));
  const name = filename?.split('/');
  const res = await fetch(baseUrl || filename);
  const buf = await res.arrayBuffer();
  const file = new File([buf], name[name.length - 1], { type: mimeType });
  cd(file);
};
export const openInNewTab = (urlParam: string) => {
  const changeUrl = () => {
    if (!urlParam.startsWith('https')) {
      if (urlParam.startsWith('http')) return urlParam;
      return `https://${urlParam}`;
    }
    return urlParam;
  };
  localStorage.setItem('openNewTab', 'true');
  const newWindow = window.open(changeUrl(), '_blank', 'noopener,noreferrer');
  if (newWindow && newWindow.opener) {
    newWindow.opener = null;
  }
};

export const spliteStrings = (chaine: string) => {
  return chaine.length > 100 ? chaine.substring(0, 100) + '...' : chaine;
};

export const currency = (country: string) => {
  switch (country) {
    case 'ma':
      return { currency: 'DHS', fractionalUnit: 'centimes' };
    case 'ga1':
    case 'ga2':
    case 'ne':
    case 'td':
    case 'bj':
    case 'ml':
    case 'cm':
    case 'sn':
    case 'ci':
      return { currency: 'F', fractionalUnit: 'centimes' };
    case 'dj':
      return { currency: 'FDJ', fractionalUnit: 'centimes' };
    case 'fr':
    case 'be':
      return { currency: '€', fractionalUnit: 'cents' };
    case 'so':
      return { currency: '$', fractionalUnit: 'cents' };
    case 'tn':
      return { currency: 'DT', fractionalUnit: 'centimes' };
    default:
      return { currency: 'DHS', fractionalUnit: 'centimes' };
  }
};

export const fix = (country: string) => {
  switch (country) {
    case 'ma':
      return 2;
    case 'tn':
      return 3;
    default:
      return 2;
  }
};

export const getColumns = (param: number, type = 'extra-small'): ItemLoaderColumns => {
  const emptyArray: Array<LoaderType> = new Array(param).fill({ type });
  return emptyArray as unknown as ItemLoaderColumns;
};

export const newfixeNumberByCountry = (number: string | number, country: string) =>
  (+number).toLocaleString('en-US', { minimumFractionDigits: fix(country), maximumFractionDigits: fix(country) });

export const newfixeNumberByCountryDecimal = (number: string | number, country: string) => {
  const formattedNumber = (+number).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: fix(country),
  });

  // Remove unnecessary trailing zeros after formatting
  return formattedNumber.replace(/\.0+$/, '');
};
export const fixeNumberByCountry = (number: string | number, country: string) => (+number).toFixed(fix(country));

export function formatNumberWithSpaces(number: number) {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } catch (e) {
    console.error('Cannot format number:', e);
  }
}
export const areEqual = (prevProps: any, nextProps: any) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

export const getTax = (id: string, taxesOptions: TaxesOptionsData[]) => {
  const taxData = taxesOptions?.find((el) => el?.value === id);
  if (taxData && 'rate' in taxData) {
    return +taxData?.rate;
  }
  return 0;
};

export const getViewPurcentage = (item: Product, params: User) => {
  if (!item?.discount_rate && !item?.discount_type && !item?.discount) {
    return fixeNumberByCountry(0, params.subdomain) + '%';
  }
  if (item?.discount_rate || item?.discount_type === 'percentage') {
    return fixeNumberByCountry(item.discount_rate || item?.discount, params.subdomain) + '%';
  } else {
    const gap = +item?.unit_original_price - +item?.unit_price;
    return fixeNumberByCountry((gap * 100) / +item.unit_original_price, params.subdomain) + '%';
  }
};
export const getUnitPrice = (item: GetProductData) => {
  let unit_price = item?.unitPriceSelect !== undefined ? +item?.unitPriceSelect : +item?.unit_price;
  if (item?.discount_type) {
    if (item?.discount_type === 'percentage') {
      unit_price = unit_price - (unit_price * item?.discount) / 100;
    } else if (item?.discount_type === 'amount') {
      unit_price = unit_price - item?.discount;
    }
  }
  return unit_price;
};

export const getMaxDiscount = (item: GetProductData | Product, options: ProductsGategoriesMaxDiscountsOptionsData[]) => {
  const el = options?.find((el) => +el?.product_category_id === +item?.product_category_id);
  if (el && el?.max_discount) {
    return el;
  }
  return null;
};

export const getErrorPercentage = (product: GetProductData, country: string) => {
  const { unitPrice, multiprice, unitPriceSelect, sale_price, has_max_discount } = product;
  const sale_price_if_is_multi = +multiprice ? +unitPriceSelect : +sale_price;
  let purcentage = +fixeNumberByCountry(100 - (+unitPrice * 100) / sale_price_if_is_multi, country);
  purcentage = purcentage > 0 ? purcentage : 0;
  const discountvalue = fixeNumberByCountry((+sale_price_if_is_multi * +purcentage) / 100, country);
  if (has_max_discount) {
    if (has_max_discount?.max_discount_type === 'percentage' && +has_max_discount?.max_discount < purcentage) {
      return has_max_discount;
    } else if (has_max_discount?.max_discount_type === 'amount' && +has_max_discount?.max_discount < +discountvalue) {
      return has_max_discount;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getDiscountInvoice = (
  item: GetProductData,
  country: string,
  default_discounts:
    | {
        [key: string]: {
          discount: string;
          discount_type: string;
        };
      }
    | undefined,
) => {
  const data = {
    ...item,
    discount: item?.discount,
    discountFixed: fixeNumberByCountry(item?.discount, country),
    discountType: item?.discount_type,
    discount_type: item?.discount_type,
  };
  if (default_discounts && default_discounts[item.product_category_id]) {
    data.discount = +default_discounts[item.product_category_id]?.discount;
    data.discountFixed = fixeNumberByCountry(data.discount, country);
    data.discountType = default_discounts[item.product_category_id]?.discount_type;
    data.discount_type = default_discounts[item.product_category_id]?.discount_type;
    return data;
  } else {
    data.discount = item?.discount || +fixeNumberByCountry('0', country);
    data.discountFixed = data.discount ? fixeNumberByCountry(data.discount, country) : fixeNumberByCountry('0', country);
    data.discountType = item.discount_type || 'percentage';
    data.discount_type = item.discount_type || 'percentage';
    return data;
  }
};
export const unifyData = (series: ChartBarStackType[]): ChartBarStackType[] => {
  const allDates = new Set<string>();

  // Collect all unique dates from all series
  series?.forEach((s) => {
    s?.data?.forEach((point) => {
      allDates?.add(point.x);
    });
  });

  const sortedDates = Array.from(allDates)?.sort();

  const unifiedSeries = series?.map((s) => {
    const dataMap = new Map(s?.data?.map((point) => [point.x, point.y]));

    // Fill in missing dates with y value as 0
    const unifiedData = sortedDates?.map((date) => {
      return { x: date, y: dataMap.get(date) || 0 };
    });

    return {
      ...s,
      data: unifiedData,
    };
  });
  return unifiedSeries;
};

interface DataEntry {
  x: string;
  y: number;
}

interface DataSet {
  name: string;
  legend_name: string;
  data: DataEntry[];
}

export function groupByMonth(data: DataSet[]): DataSet[] {
  return data.map((item) => {
    const groupedData = item.data.reduce<{ [key: string]: number }>((acc, current) => {
      const monthYear = dayjs(current.x).format('YYYY-MM');
      if (!acc[monthYear]) {
        acc[monthYear] = 0;
      }
      acc[monthYear] += current.y;
      return acc;
    }, {});
    const formattedData: DataEntry[] = [];
    for (const [monthYear, value] of Object.entries(groupedData)) {
      formattedData.push({
        x: monthYear + '-01',
        y: value,
      });
    }
    return {
      ...item,
      data: formattedData,
    };
  });
}

export function formatXaxis(data: ChartDaum[]) {
  // Extract unique x-axis categories (timestamps)
  const xaxisCategories: number[] = [];
  data.forEach((series) => {
    series.data.forEach((point) => {
      if (!xaxisCategories.includes(point[0])) {
        xaxisCategories.push(point[0]);
      }
    });
  });
  xaxisCategories.sort((a, b) => a - b);
  return {
    categories: xaxisCategories,
    labels: {
      formatter: function (value: number): string {
        return new Date(value).toLocaleDateString();
      },
    },
  };
}

export function getDatesBetween(startDate: string, endDate: string): string[] {
  const dates = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
}
export const queryString = (dataForm: { [key: string]: any }) =>
  Object.entries(dataForm)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

export const setBgColor = (
  trRefs: MutableRefObject<HTMLTableRowElement[]>,
  containerRef?: MutableRefObject<HTMLDivElement | null>,
  order = false,
  currentIndex?: number,
) => {
  setTimeout(() => {
    if (trRefs?.current) {
      // console.log('trRefs?.current', trRefs?.current);
      trRefs?.current?.map((_, index) => {
        if (trRefs?.current && trRefs?.current[index]) {
          const tdList = trRefs?.current[index]?.querySelectorAll('td');
          const topPosition = trRefs?.current[index]?.offsetTop;

          if (
            (order && typeof currentIndex === 'number' && index === currentIndex) ||
            (!order && index === 0) ||
            (order && currentIndex === undefined && index === trRefs?.current?.length - 1)
          ) {
            if (tdList && tdList?.length > 0) {
              if (containerRef && containerRef.current) containerRef.current.scrollTop = topPosition - 60;
              setTimeout(() => {
                tdList.forEach((td) => {
                  const isAlreadyDisplayed = td.classList.contains('click');
                  !isAlreadyDisplayed && td.classList.add('click');
                });
              }, 200);
            }
          } else {
            if (tdList && tdList?.length > 0)
              tdList.forEach((td) => {
                td.classList.remove('click');
              });
          }
        }
      });
    }
  }, 60);
  setTimeout(() => {
    if (trRefs?.current) {
      trRefs?.current?.map((_, index) => {
        if (trRefs?.current && trRefs?.current[index]) {
          const tdList = trRefs?.current[index]?.querySelectorAll('td');
          if (tdList && tdList?.length > 0)
            tdList.forEach((td) => {
              td.classList.remove('click');
            });
        }
      });
    }
  }, 400);
};
export const setDIVBgColor = (
  divRef: MutableRefObject<HTMLDivElement[]>,
  containerRef?: MutableRefObject<HTMLDivElement | null>,
  order = false,
  currentIndex?: number,
) => {
  setTimeout(() => {
    if (divRef?.current) {
      divRef?.current?.map((_, index) => {
        if (divRef?.current && divRef?.current[index]) {
          const tdList = divRef?.current[index]?.querySelectorAll('.td');
          const topPosition = divRef?.current[index]?.offsetTop;

          if (
            (order && typeof currentIndex === 'number' && index === currentIndex) ||
            (!order && index === 0) ||
            (order && currentIndex === undefined && index === divRef?.current?.length - 1)
          ) {
            if (tdList && tdList?.length > 0) {
              if (containerRef && containerRef.current) containerRef.current.scrollTop = topPosition - 60;
              setTimeout(() => {
                tdList.forEach((td) => {
                  const isAlreadyDisplayed = td.classList.contains('click');
                  !isAlreadyDisplayed && td.classList.add('click');
                });
              }, 200);
            }
          } else {
            if (tdList && tdList?.length > 0)
              tdList.forEach((td) => {
                td.classList.remove('click');
              });
          }
        }
      });
    }
  }, 0);
  setTimeout(() => {
    if (divRef?.current) {
      divRef?.current?.map((_, index) => {
        if (divRef?.current && divRef?.current[index]) {
          const tdList = divRef?.current[index]?.querySelectorAll('.td');
          if (tdList && tdList?.length > 0)
            tdList.forEach((td) => {
              td.classList.remove('click');
            });
        }
      });
    }
  }, 400);
};
export const setNewBgColor = (selectedProduct: HTMLElement) => {
  setTimeout(() => {
    if (selectedProduct) {
      selectedProduct.classList.add('newClick');
    }
  }, 0);
  setTimeout(() => {
    if (selectedProduct) {
      selectedProduct.classList.remove('newClick');
    }
  }, 1000);
};
export const handleNavigate = (navigate: () => void, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
  e?.preventDefault();
  const shouldNavigate = window.confirm(
    'Vous êtes sur le point de quitter le formulaire et toutes les données saisies sur cette page seront perdues, souhaitez-vous continuer tout de même ?',
  );
  if (shouldNavigate) {
    navigate();
  }
};
export const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str?.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str?.substr(o, size);
  }

  return chunks;
};

export const getPriceAfterTva = (price: string, country: string) => {
  return fixeNumberByCountry(Math.round((+price / 1.07) * 10) / 10, country);
};
export const getPriceAfterTva0to7 = (price: string, country: string) => {
  return fixeNumberByCountry(+price * 1.07, country);
};
export const getTvaMultiPrices = (item: GetProductData, pricingField: string, ppv: string, pph: string, subdomain: string) => {
  let t: any[] = [];
  if (item.prices?.length > 0) {
    const p = item.prices?.map((el) => [
      {
        ...el,
        price: el?.price,
        tva: { id: '35', label: 'Exonéré (0.00%)' },
      },
      {
        ...el,
        price: getPriceAfterTva0to7(el?.price, subdomain),
        tva: { id: '36', label: 'TVA (7.00%)' },
      },
    ]);
    t = [
      ...p,
      [
        {
          price: item?.[pricingField as keyof GetProductData],
          ID: undefined,
          tva: { id: '35', label: 'Exonéré (0.00%)' },
        },
        {
          price: pricingField === 'sale_price' ? ppv : pph,
          ID: undefined,
          tva: { id: '36', label: 'TVA (7.00%)' },
        },
      ],
    ];
  } else {
    t = [
      [
        {
          price: item?.[pricingField as keyof GetProductData],
          ID: undefined,
          tva: { id: '35', label: 'Exonéré (0.00%)' },
        },
        {
          price: pricingField === 'sale_price' ? ppv : pph,
          ID: undefined,
          tva: { id: '36', label: 'TVA (7.00%)' },
        },
      ],
    ];
  }
  return t;
};

export const reloadPage = () => {
  try {
    if (window.self !== window.top) {
      console.error('Cannot reload page because it is inside an iframe with restrictions.');
    } else if (window.location.origin !== window.parent.location.origin) {
      console.error('Cannot reload page due to cross-origin restrictions.');
    } else {
      window.location.reload();
    }
  } catch (e) {
    console.error('Reload failed with error:', e);
    // Fallback: Adding a query parameter to the current URL
    window.location.href = `${window.location.pathname}?reload=${new Date().getTime()}`;
  }
};

export const exactOnLogout = () => {
  const __offline__preferences = localStorage.getItem('__offline__preferences');
  if (!__offline__preferences) {
    if (!window.indexedDB) {
      // console.log('IndexedDB is not supported on this browser.');
      return;
    }
    indexedDB.deleteDatabase('invoices');
    indexedDB.deleteDatabase('user');
    indexedDB.deleteDatabase('options');
  }
  localStorage.removeItem('phoneConfirmation');
  localStorage.removeItem('groupsNotif');
  const reloadChannel = new BroadcastChannel('reloadChannel');
  reloadChannel.postMessage({ action: 'reload' });
};

export function cleanObject(obj: Record<string, any>): Record<string, any> {
  const result = { ...obj }; // Clone the original object to avoid mutation

  Object.keys(result).forEach((key) => {
    const value = result[key];

    // If the value is an object (including arrays), recursively clean it
    if (typeof value === 'object' && value !== null) {
      // Check if it's an array and remove if empty
      if (Array.isArray(value)) {
        if (value.length === 0) {
          delete result[key];
        }
      } else {
        // Clean the object recursively
        result[key] = cleanObject(value);

        // If the cleaned object is empty, remove the key
        if (Object.keys(result[key]).length === 0 || Object.values(result[key]).every((v) => !v)) {
          delete result[key];
        }
      }
    }
    // If the value is falsy (empty string, null, undefined, etc.), delete it
    else if (!value) {
      delete result[key];
    }
  });

  return result;
}

export const safeLocalStorage = {
  getItem: (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'SecurityError') {
        console.error(`Access denied for localStorage.getItem('${key}'):`, e);
      }
      return null; // Fallback value
    }
  },
  setItem: (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'SecurityError') {
        console.error(`Access denied for localStorage.setItem('${key}'):`, e);
      }
    }
  },
  removeItem: (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'SecurityError') {
        console.error(`Access denied for localStorage.removeItem('${key}'):`, e);
      }
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (e) {
      if (e instanceof DOMException && e.name === 'SecurityError') {
        console.error('Access denied for localStorage.clear():', e);
      }
    }
  },
};
export const isInputFocused = () => {
  const activeElement = document.activeElement;
  return (
    activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA' || activeElement?.getAttribute('contenteditable') === 'true'
  );
};
