import { useEffect } from 'react';

const stylesConfig: Record<string, Record<string, string>> = {
  '1': {
    '--sob-primary-400': '#25b8aa',
    '--sob-primary-500': '#22a99c',
    '--sob-primary-100': '#e5f7f5',
    '--sob-primary-200': '#d2f0ed',
    '--sob-color-background-default-header': '#25b8aa',
    '--sob-v2-color-header': '#ffffff',
    '--sob-v2-color-logo': '#fff',
    '--sob-v2-color-icon': '#fff',
    '--sob-v2-color-logo-text': '#fff',
  },
  '2': {
    '--sob-primary-400': '#198880',
    '--sob-primary-500': '#186D67',
    '--sob-primary-100': '#F1FCF9',
    '--sob-primary-200': '#D0F7F0',
    '--sob-color-background-default-header': '#198880',
    '--sob-v2-color-header': '#F1FCF9',
    '--sob-v2-color-logo': '#fff',
    '--sob-v2-color-icon': '#fff',
    '--sob-v2-color-logo-text': '#fff',
  },
  default: {
    '--sob-primary-400': '#198880',
    '--sob-primary-500': '#186D67',
    '--sob-primary-100': '#F1FCF9',
    '--sob-primary-200': '#D0F7F0',
    '--sob-color-background-default-header': '#FFF',
    '--sob-v2-color-header': '#2B4660',
    '--sob-v2-color-logo': '#25b8aa',
    '--sob-v2-color-icon': '#2B4660',
    '--sob-v2-color-logo-text': '#000',
  },
};

export const getStyles = (value: string): void => {
  const root = document.querySelector(':root') as HTMLElement;
  if (root) {
    try {
      const styleValues = stylesConfig[value] || stylesConfig.default;
      Object.entries(styleValues).forEach(([key, val]) => {
        root.style.setProperty(key, val);
      });
    } catch (error) {
      // Sentry.captureException(error);
    }
  }
};

const useApplyGlobalStyles = (): { isMobile: boolean } => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    const myElement = document.querySelector('html');
    if (myElement) {
      if (isMobile) {
        myElement.classList.add('mobile_device');
      } else {
        myElement.classList.add('device');
        const screenSize = localStorage.getItem('screenSize') || '1';
        const theme = localStorage.getItem('theme') || '1';
        myElement.style.fontSize = `${screenSize}rem`;

        getStyles(theme);
      }
    }
  }, [isMobile]);

  return { isMobile };
};

export default useApplyGlobalStyles;
